/**
 * @param prescriptionNumber Defines the specific RX that the caregiver uses to add the dependent
 * @returns correct translation for every error case.
 */
export const mapAddFamilyMemberErrors = (prescriptionNumber: string): Record<string, string> => ({
    [`No result found for prescriptionNumber: ${prescriptionNumber}`]:
        'components.addFamilyMember.errorModals.noResultsFoundError',
    [`Patient details are invalid for prescriptionNumber: ${prescriptionNumber}`]:
        'components.addFamilyMember.errorModals.rxNotMatchError',
    [`Patient prescription ${prescriptionNumber} is invalid, rx need to be filled within 12 months`]:
        'components.addFamilyMember.errorModals.rxExpired',
    [`Prescription ${prescriptionNumber} is already associated.`]:
        'components.addFamilyMember.errorModals.invalidDetails',
    [`Dependent has already been added to the Caregiver`]: 'components.addFamilyMember.errorModals.alreadyAssociated',
    [`Dependent is already associated with another family member`]:
        'components.addFamilyMember.errorModals.alreadyAssociated'
});

/**
 * @param errors Gets an Array of strings with the error responses
 * @param prescriptionNumber Defines the specific RX that the caregiver uses to add the dependent
 * @returns the correct translation for the error modal.
 */
export const mapFamilyAccountErrors = (errors: string[], prescriptionNumber: string): string | undefined => {
    const possibleErrors = mapAddFamilyMemberErrors(prescriptionNumber);

    for (const error of errors) {
        if (Object.prototype.hasOwnProperty.call(possibleErrors, error)) {
            return possibleErrors[error];
        }
    }
    return undefined;
};
