// General
import { navigate } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';

// Hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Display components
import FamilyAccountCardList from 'display-components/family-management/family-account-card-list';
import { FamilyAccountRemoveMemberModalContent } from 'display-components/add-family-member/modals';

// Ui-ki
import DropdownCustom from 'ui-kit/dropdown-custom';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import { Dependent } from 'state/family-profile/family-profile.reducers';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

// State, interfaces and types
import {
    familyProfileDependentsSelector,
    familyProfileIsLoadingSelector
} from 'state/family-profile/family-profile.selectors';
import {
    familyProfileSendDependentInvitationRoutine,
    familyProfileGetDependentsRoutine,
    familyProfileRemoveDependentRoutine
} from 'state/family-profile/family-profile.routines';
import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

// Utils
import storageHelper from 'util/storageHelper';

// Helper function to add suffix to the day format.
const nthDay = (day: number): string => {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
        case 1:
            return `${day}st`;
        case 2:
            return `${day}nd`;
        case 3:
            return `${day}rd`;
        default:
            return `${day}th`;
    }
};

// Helper function to convert date to an ordinal format.
const dateOrdinalFormat = (date: string): string => {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString('en-US', { month: 'long' });
    const year = dateObject.getFullYear();

    return `${month} ${nthDay(day)}, ${year}`;
};

// Main component
const FamilyAccountList = () => {
    // General
    const cookie = storageHelper.cookies;

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [epostPatientNum, setEpostPatientNum] = useState<string>('');

    // Selectors
    const profileObject = useSelector(accountProfileSelector);
    const dependents = useSelector(familyProfileDependentsSelector);
    const isFamilyAccountLoading = useSelector(familyProfileIsLoadingSelector);

    // Get profile ePostPatientNum.
    useEffect(() => {
        if (profileObject && profileObject.epostPatientNum) {
            setEpostPatientNum(profileObject.epostPatientNum);
        }
    }, [profileObject]);

    useEffect(() => {
        dispatch(familyProfileGetDependentsRoutine.trigger());
    }, [dispatch]);

    const handleRemoveFamilyMember = (id: number, name: string) => {
        dispatch(
            openModal({
                showClose: false,
                size: 'lg',
                type: 'danger',
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.familyAccount.areYouSureQuestion')} icon="alert" />
                ),
                bodyContent: (
                    <FamilyAccountRemoveMemberModalContent
                        name={name}
                        translation={t}
                        onCancelClick={() => dispatch(closeModal({}))}
                        onContinueClick={() => {
                            dispatch(
                                familyProfileRemoveDependentRoutine.trigger({
                                    DependentID: id,
                                    onSuccess: () => dispatch(closeModal({})),
                                    onFailure: () => dispatch(closeModal({}))
                                })
                            );
                        }}
                    />
                )
            })
        );
    };

    const handleCancelInvitationFamilyMember = (id: number) => {
        dispatch(
            familyProfileRemoveDependentRoutine.trigger({
                DependentID: id,
                onSuccess: () => dispatch(closeModal({})),
                onFailure: () => dispatch(closeModal({}))
            })
        );
    };

    const handleResendInvitationFamilyMember = (dependent: Dependent) => {
        /**
         * Ticket: DRX-2668
         * This cookie is needed to remember the communication method used
         * during the send invitation on add dependent flow.
         */
        const communicationMethod =
            cookie.getDependentSendInvitationMethodCookie(epostPatientNum, dependent.id, dependent.ePostPatientNum) ||
            0;

        dispatch(
            familyProfileSendDependentInvitationRoutine.trigger({
                data: {
                    CommunicationType: communicationMethod,
                    EpostPatientNumber: dependent.ePostPatientNum,
                    CareGiverDependentId: dependent.id
                }
            })
        );
    };

    const dependentsPayload = dependents
        // After adding a dependent to an account, even removing it from the list,
        // its relationship will not disappear. To remove a user that is not part
        // of the family account list, the dateRevokeAccess needs to be NULL.
        .filter((dependent) => !dependent.dateRevokeAccess)
        .map((dependent) => {
            const showRemoveButton =
                dependent.accountStatus === 'fullAccess' ||
                dependent.accountStatus === 'partialAccess' ||
                dependent.accountStatus === 'declined';

            const showInvitationButtons =
                dependent.accountStatus === 'invitePending' ||
                dependent.accountStatus === 'expired' ||
                dependent.accountStatus === 'declined';

            return {
                ...dependent,
                dateRequested: dateOrdinalFormat(dependent.dateRequested),
                expiredDate: dateOrdinalFormat(dependent.dateInviteExpiration),
                onRemoveFamilyMember: showRemoveButton
                    ? () => handleRemoveFamilyMember(dependent.id, dependent.familyMemberFirstName)
                    : undefined,
                onCancelInvitationFamilyMember: showInvitationButtons
                    ? () => handleCancelInvitationFamilyMember(dependent?.id)
                    : undefined,
                onResendInvitationFamilyMember: showInvitationButtons
                    ? () => handleResendInvitationFamilyMember(dependent)
                    : undefined
            };
        });

    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className="family-account-page-actions">
                        <DropdownCustom
                            label={t('pages.profile.familyAccount.familySection.dropdown.label')}
                            options={[
                                {
                                    label: t('pages.profile.familyAccount.familySection.dropdown.options.adult'),
                                    onClick: () => navigate('/secure/profile/family-account/add-an-adult')
                                },
                                {
                                    label: t('pages.profile.familyAccount.familySection.dropdown.options.minor'),
                                    onClick: () => navigate('/secure/profile/family-account/add-a-minor')
                                }
                                // DRX-2757: hidden pets for 6.0 release
                                // {
                                //     label: t('pages.profile.familyAccount.familySection.dropdown.options.pet'),
                                //     onClick: () => navigate('/secure/profile/family-account/add-a-pet')
                                // }
                            ]}
                        />
                    </div>
                    {isFamilyAccountLoading ? (
                        <LoadingMessage
                            text={t(`pages.profile.familyAccount.familySection.loadingMessage`)}
                            isVisible={isFamilyAccountLoading}
                        />
                    ) : dependentsPayload.length > 0 ? (
                        <FamilyAccountCardList payload={dependentsPayload} />
                    ) : (
                        <p className="profile-empty-message">
                            {t(`pages.profile.familyAccount.familySection.emptyMessage`)}
                        </p>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default FamilyAccountList;
