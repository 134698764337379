import { ReactElement } from 'react';
import classNames from 'classnames';

// Components
import { Dropdown as ReactDropdown } from 'react-bootstrap';

// Ui-kit: icons
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

// Styles
import './dropdown-custom.style.scss';

// Interface and Types
interface DropdownCustomProps {
    label: string;
    options: DropdownCustomOptions[];
    className?: string;
}

interface DropdownCustomOptions {
    label: string;
    href?: string;
    onClick?: () => void;
}

// Main component.
const DropdownCustom = ({ label, options, className }: DropdownCustomProps): ReactElement => {
    const containerClasses = classNames('dropdown-custom', { [`${className}`]: className });

    return (
        <ReactDropdown className={containerClasses}>
            <ReactDropdown.Toggle variant="custom" id="dropdown-basic">
                {label} <ChevronIcon direction="down" />
            </ReactDropdown.Toggle>

            {options.length > 0 && (
                <ReactDropdown.Menu>
                    {options.map(({ label, href, onClick }, index) => (
                        <ReactDropdown.Item key={`react-dropdown-item-${index}`} href={href} onClick={onClick}>
                            {label}
                        </ReactDropdown.Item>
                    ))}
                </ReactDropdown.Menu>
            )}
        </ReactDropdown>
    );
};

export default DropdownCustom;
