import { useMemo } from 'react';
import { graphql } from 'gatsby';
import { useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components & UI
import ProfileLayout from 'components/layouts/profile/profile.layout';
import FamilyAccountList from 'components/family-account-list';
import MyCaregiversList from 'components/my-caregivers-list';

// Styles
import './family-account-page.style.scss';
import { accountIsMinorSelector } from 'state/account/account.selectors';

const FamilyAccount: React.FC = () => {
    const { t } = useTranslation();
    const isMinor = useSelector(accountIsMinorSelector);

    const sections = useMemo(() => {
        const myCaregiversSection = {
            heading: t(`pages.profile.familyAccount.myCaregiversSection.heading`),
            children: <MyCaregiversList />
        };

        if (isMinor) {
            return [myCaregiversSection];
        }

        const familySection = {
            heading: t(`pages.profile.familyAccount.familySection.heading`),
            children: <FamilyAccountList />
        };

        return [familySection, myCaregiversSection];
    }, [isMinor, t]);

    return (
        <div className="family-account-page">
            <ProfileLayout
                eyebrowText={t(`pages.profile.eyebrowText`)}
                title={t(`pages.profile.familyAccount.title`)}
                heading={t(`pages.profile.familyAccount.familySection.heading`)}
                sections={sections}
            />
        </div>
    );
};

export default FamilyAccount;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
